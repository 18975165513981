<template>
  <div class="p-1">
    <div class="flex flex-wrap justify-between items-center mb-2">
      <h3 class="text-2xl">Solicitudes de documentos</h3>
      <a-dropdown v-if="user.isStudent">
        <a-button>
          Solicitar
        </a-button>

        <template #menu>
          <a-dropdown-item v-if="types.length === 0">
            {{ loading ? 'Cargando...' : 'No hay opciones disponibles' }}
          </a-dropdown-item>
          <a-dropdown-item v-for="type in types" :key="type.value" @click="request(type)" close-on-select>
            <div class="text-left w-full">
              {{ type.label }}
            </div>
          </a-dropdown-item>
        </template>
      </a-dropdown>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      :pagination="pagination"
      @paginate="search({ page: $event })"
      toolbar-column-filter
      hint="Puedes ver la razón de cancelación pasando el cursor sobre el estado"
      @toolbar(refresh)="search({ page: 1 })">

      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>

      <!-- <template #td:cancellation_reason="{key}">
          <div class="line-clamp-3 w-64" :title="key" v-tooltip="key">
              {{ key }}
          </div>
      </template> -->
      <template #td(status)="{key, item}">
                <span v-if="item.status === 'canceled'" v-tooltip="item.cancellation_reason" class="cursor-default">
                    {{ key }}
                </span>
                <span v-else class="cursor-default">
                    {{ key }}
                </span>
      </template>
      <template #td(actions)="{key, item}">
        <a-dropdown>
          <button class="w-8 h-8 rounded-full border focus:outline-none text-center focus:ring-2 focus:ring-primary">
            <a-icon name="more" class="block mx-auto transform rotate-90" />
          </button>
          <template #menu>
            <template v-if="user.isAdmin">
              <a-dropdown-item v-for="(type, k) in statusTypes(item)" :key="k" @click="type.action ? type.action(key) : handleStatusChange(type.endpoint, key, type.document)" :disabled="type.loading">
                <!-- <a-loader v-if="type.loading" /> --> {{ type.label }}
              </a-dropdown-item>
            </template>
            <a-dropdown-item @click="remove(key)" v-if="!item.retired_at">
              <a-loader v-if="loadings.deleting.includes(key)" /> Eliminar
            </a-dropdown-item>
            <p v-if="item.generated_at && item.retired_at" class="p-1 text-center">
              No hay opciones disponibles
            </p>
          </template>
        </a-dropdown>
      </template>
    </a-table>

    <a-modal
      @confirm="cancel"
      @cancel="cancelling = null"
      :title="`Esta por cancelar ${selected.document} de ${selected.student.first_name} ${selected.student.last_name}`"
      :disabled-confirm="!reason || reason.length > 255"
      :show="cancelling !== null">
      <div v-if="cancelling" class="w-full">
        <a-input
          counter
          required
          :error="reason.length > 255 ? 'No puede tener mas de 255 carácteres': null"
          label="Razón"
          type="textarea"
          id="cancelation_reason"
          class="block"
          v-model="reason" />
        <small>Para continuar ingrese su razón de cancelación</small>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    query: {
      limit: 10,
      search: '',
      order_by: 'created_at,desc',
      with: 'student'
    },
    cancelling: null,
    reason: '',
    types: [],
    loadings: {
      statusChanging: [],
      deleting: []
    },
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.documentsRequests.pagination,
      loading: state => state.documentsRequests.loadings.index
    }),
    ...mapGetters(['lang']),
    selected() {
      return this.pagination.data.find($0 => $0.id === this.cancelling) ?? { student: {} }
    },
    columns() {
      return [
        { title: 'Fecha de solicitud', key: 'created_at', mutate: ({ key }, { date }) => key ? date(key, 'YYYY-MM-DD [a las] HH:mm')  : 'Nunca', display: true },
        { title: 'documento', key: 'document', display: true },
        { title: 'Solicitada por', key: 'student', mutate: ({ key }) => `${key.last_name}, ${key.first_name} `, display: this.user.isAdmin },
        { title: 'Fecha de retiro', key: 'retired_at', mutate: ({ key }, { date }) => key ? date(key, 'YYYY-MM-DD [a las] HH:mm')  : 'Nunca', display: true },
        { title: 'Estado', key: 'status', display: true, slot: 'status', mutate: ({ key }) => this.mapStatus(key) },
        { title: 'Acciones', key: 'id', slot: 'actions', display: this.user.isAdmin },
      ].filter($0 => $0.display)
    }
  },
  methods: {
    ...mapActions({
      fetch: 'documentsRequests/index',
      remove: 'documentsRequests/delete'
    }),
    statusTypes(item) {
      const includes = this.loadings.statusChanging.includes(item.id)
      const { markGenerated, markRetired, markCanceled } = this.$repository.documentRequests

      return [
        { endpoint: markGenerated, label: 'Enviar documento', loading: includes, display: !item.generated_at, document: item.document_request_type_id },
        { endpoint: markRetired, label: 'Marcar como retirada', loading: includes, display: item.generated_at && !item.retired_at },
        { endpoint: markCanceled, label: 'Marcar como cancelada', loading: includes, display: !item.generated_at && !item.retired_at, action: (id) => this.cancelling = id },
      ].filter($0 => $0.display)
    },
    search(query = {}) {
      this.fetch({ ...this.query, ...query })
    },
    mapStatus(status) {
      if (!status) return 'Pendiente'

      switch(status) {
        case 'retired': return 'Retirado'
        case 'canceled': return 'Cancelado'
        case 'generated': return 'Correo enviado'
        default: return status
      }
    },
    cancel() {
      this.$repository.documentRequests
        .markCanceled(this.cancelling, { cancellation_reason: this.reason })
        .then(() => {
          this.search()
          this.cancelling = null
        })
    },
    handleStatusChange(promise, id, document) {
      this.confirm(() => {
        this.loadings.statusChanging.push(id)
        promise(id, document)
          .then(() => this.search())
          .finally(() => {
            const index = this.loadings.statusChanging.findIndex($0 => $0 === id)
            if (index >= 0) {
              this.loadings.statusChanging.splice(index, 1)
            }
          })
      })
    },
    remove(id) {
      this.confirm(() => {
        this.loadings.deleting.push(id)
        this.$repository.documentRequests
          .delete(id)
          .then(() => this.search())
          .finally(() => {
            const index = this.loadings.deleting.findIndex($0 => $0 === id)
            if (index >= 0) {
              this.loadings.deleting.splice(index, 1)
            }
          })
      })
    },
    confirm(callback) {
      this.$confirm({
        message: `¿Desea continuar con esta solicitud?`,
        button: {
          no: 'No',
          yes: 'Si'
        },
        callback: confirm => {
          if (confirm) {
            callback()
          }
        }
      })
    },
    request(type) {
      this.$confirm({
        message: `¿Desea solicitar ${type.label}?`,
        button: {
          no: 'No',
          yes: 'Si'
        },
        callback: confirm => {
          if (confirm) {
            this.$repository.documentRequests
              .store({ document_request_type_id: type.value })
              .then(() => {
                this.$message.success({
                  message: `Por favor debe dirigirse a la administración del instituto a realizar la cancelación del documento.`,
                  duration: 5000
                })
                this.search()
              })
          }
        }
      })
    }
  },
  mounted() {
    Promise.all([
      this.$repository.documentRequestsTypes.index({ limit: 0 }),
      this.fetch({ ...this.query, page: this.$route.query.page || 1 })
    ])
      .then(([{ data: types }]) => {
        this.types = types.map($0 => ({ label: $0.name, value: $0.id }))
      })
  }
}
</script>
